import { useEffect, useState } from "react"
import { RootState } from "../../../store"
import * as mapSlice from "../map-slice"
import { connect, ConnectedProps  } from 'react-redux'
import * as mapComponents from "../map-components"
import { ICompanyAddressModel, ICoordinates } from "../../../shared/models/company-address-models"
import "./MainMapScreen.scss"
import { fetchCompanyAddresses, fetchMapFilters, setSelectedFilter, setBoundingBox } from "../map-slice"

const mapStateToProps = (state: RootState) => ({
    companyAddresses: mapSlice.getCompanyAddresses(state),
    mapFilters: mapSlice.getMapFilters(state),
    loading: mapSlice.getLoading(state),
    boundingBox: mapSlice.getBoundingBox(state)
})

const mapDispatchToProps = {
    dispatchActions: mapSlice.sliceActions,
    fetchCompanyAddresses,
    fetchMapFilters,
    setSelectedFilter,
    setBoundingBox
};

const connector = connect(mapStateToProps, mapDispatchToProps)

type MainMapScreenProps = ConnectedProps<typeof connector>;


const MainMapScreen = (props: MainMapScreenProps) => {

    const companyAddresses = props.companyAddresses
    const [showSideBar, setShowSideBar] = useState(false)
    const [selectedCompanyAddress, setSelectedCompanyAddress] = useState<ICompanyAddressModel | null>(null)

    useEffect(() => {
        if(!props.mapFilters) {
            props.fetchMapFilters()
        }
    }, [props.mapFilters])

    useEffect(() => {
        const selectedFilter = props.mapFilters?.find((x) =>{if(x.selected === true) return x})
        props.fetchCompanyAddresses(props?.boundingBox || [], selectedFilter)
    }, [props.boundingBox, props.mapFilters])

    const handleAddressClick = (company_address: ICompanyAddressModel) => {
        setSelectedCompanyAddress(company_address)
        setShowSideBar(true)
    }


    return (
        <> 
            {!!selectedCompanyAddress && 
                <mapComponents.MapSideModal 
                    showSideModal={showSideBar} 
                    companyAddress={selectedCompanyAddress}
                    onClose={() => setShowSideBar(false)}
                />
            }
            <div className="main-map-container">
                <mapComponents.MapFilters 
                    filters={props.mapFilters || []}
                    setSelectedFilter={(filter) => props.setSelectedFilter(filter)}
                />
                <mapComponents.MapBoxMapComponent 
                    companyAddresses={companyAddresses || []}
                    selectCompanyAddress={handleAddressClick}
                    fetchCompanyAddresses={(boundingBox: Array<ICoordinates>) =>  props.setBoundingBox(boundingBox)}
                />
            </div>
        </>
    )
}

export default connector(MainMapScreen)