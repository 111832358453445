import {
    createBrowserRouter
  } from "react-router-dom";

import * as screens from "../map-screens"

const MapRouter = createBrowserRouter([
  {
    path: "/",
    element: <screens.MainMapScreen />,
  },
]);


export default MapRouter