import './Footer.scss'



const Footer = () => {

    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-container">
            <div>
                {currentYear} All Rights Reserved
            </div>
        </footer>
    )
}


export default Footer