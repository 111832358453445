import { ICompanyAddressModel, ICompanyName, ICoordinates } from "../../shared/models/company-address-models";
import { GeoJSONFeatureCollection } from "./map-models";



export const get_legal_business_name = (company_names: Array<ICompanyName>) => {
    var legalCompanyName = company_names.find((x) => {
        const hasLegalName = x.tags.find((x) => x === "legal business name")
        if (hasLegalName) return x
        return
    })

    return legalCompanyName?.name
}

  
export const convertAddressToMapPoint = (companyAddresses: Array<ICompanyAddressModel>): GeoJSONFeatureCollection => {
    return {
        type: 'FeatureCollection',
        features: companyAddresses.map(address => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [address.geo_location.lon, address.geo_location.lat],
            },
            properties: {
                name: address.legaBusinessName,
                address
            },
        }))
    }
}


export const convertBoundingBox = (bounds: mapboxgl.LngLatBounds): Array<ICoordinates> => {

    const boundingBox: Array<ICoordinates> = []
    boundingBox.push({lat: bounds.getNorthWest().lat, lon: bounds.getNorthWest().lng})
    boundingBox.push({lat: bounds.getNorthEast().lat, lon: bounds.getNorthEast().lng})
    boundingBox.push({lat: bounds.getSouthEast().lat, lon: bounds.getSouthEast().lng})
    boundingBox.push({lat: bounds.getSouthWest().lat, lon: bounds.getSouthWest().lng})

    return boundingBox
  }