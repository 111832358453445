import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import {thunk} from "redux-thunk"
import {mapSlice} from "./features/map/map-slice"

export const store = configureStore({
    reducer: {
        map: mapSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;