import { Chip, Dialog, DialogContent, DialogTitle } from "@mui/material"
import { ICompanyAddressModel } from "../../../../shared/models/company-address-models"
import "./MapSideModal.scss"
import CloseIcon from '@mui/icons-material/Close';


interface IMapSideModalProps {
    showSideModal: boolean,
    companyAddress: ICompanyAddressModel
    onClose: () => void
}

const MapSideModal = (props: IMapSideModalProps) => {
    const addressDetails = props.companyAddress.address
    const processes = props.companyAddress.processes || []

    return (
        <>
            <Dialog
                open={props.showSideModal}
                onClose={() => props.onClose()}
                hideBackdrop={true}
                className="map-side-modal"
                aria-labelledby="map-details"
                disableScrollLock={true}
            >
                <CloseIcon 
                    className="modal-close" 
                    color={"disabled"}
                    onClick={props.onClose}
                />
                <DialogContent>
                    <DialogTitle id="alert-dialog-title" className="legal-business-name">
                        {props.companyAddress.legaBusinessName}
                    </DialogTitle>
                    <div className="side-modal-content">
                        <span className="modal-sub-title">ADDRESS</span>
                        <span>{addressDetails.line_1}</span>
                        <span>{addressDetails.line_2}</span>
                        <span>{addressDetails.city}, {addressDetails.state}</span>
                        <span>{addressDetails.zip}</span>
                    </div>
                    {
                        processes.length > 0 &&
                        <div className="side-modal-content">
                            <span className="modal-sub-title">PROCESSES</span>
                            <div className="process-list">
                                {processes.map((process) => {
                                    return (
                                        <Chip label={process} className="process-chip"/>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MapSideModal