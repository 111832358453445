import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk, RootState } from '../../store'
import "../../shared/models/company-address-models"
import { ICompanyAddressModel, ICoordinates } from '../../shared/models/company-address-models'
import { getCompanyAddressesAPI, getMapFiltersAPI } from '../../shared/services/api/istanbul_api'
import { get_legal_business_name } from './map-utils'
import { IMapFilter } from '../../shared/models/map-models'

export interface MapState {
  company_addresses: Array<ICompanyAddressModel> | null,
  loading: boolean,
  mapFilters: Array<IMapFilter> | null,
  boundingBox: Array<ICoordinates> | null
}

const initialState: MapState = {
    company_addresses: null,
    loading: false,
    mapFilters: null,
    boundingBox: null
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setCompanyAddresses: (state, action: PayloadAction<Array<ICompanyAddressModel>>) => {
      state.company_addresses = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setMapFilters: (state, action: PayloadAction<Array<IMapFilter>>) => {
      state.mapFilters = action.payload
    },
    setSelectedFilter: (state, action: PayloadAction<IMapFilter>) => {
      state.mapFilters?.map((filter) => {
        if(filter.name === action.payload.name)
          filter.selected = !filter.selected
        else
          filter.selected = false
        return filter
      })
    },
    setBoundingBox: (state, action: PayloadAction<Array<ICoordinates>>) => {
        state.boundingBox = action.payload
    }
  }
})

export const fetchCompanyAddresses = (
  boundingBox: Array<ICoordinates>,
  mapFilter: IMapFilter | null = null
): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))

  const processFilter = mapFilter ? [mapFilter.name.toLowerCase()] : []

  const companyAddresses = await getCompanyAddressesAPI(boundingBox, processFilter)
  companyAddresses.map((x) => x.legaBusinessName = get_legal_business_name(x.company_names) || "")
  dispatch(setCompanyAddresses(companyAddresses))
  dispatch(setLoading(false))
};

export const fetchMapFilters = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  const mapFilters = await getMapFiltersAPI()
  dispatch(
    setMapFilters(
      mapFilters.map(filter => ({...filter, selected: false}))
    )
  )
  dispatch(setLoading(false))
};

export const { setCompanyAddresses, setLoading, setMapFilters, setSelectedFilter, setBoundingBox } = mapSlice.actions

export const sliceActions = mapSlice.actions

export const getCompanyAddresses = (state: RootState) => state.map.company_addresses
export const getLoading = (state: RootState) => state.map.loading
export const getMapFilters = (state: RootState) => state.map.mapFilters
export const getBoundingBox = (state: RootState) => state.map.boundingBox

export default mapSlice.reducer