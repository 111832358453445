import { Box, AppBar, Toolbar, IconButton, Typography, Button } from "@mui/material"
import "./Navbar.scss"



const Navbar = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar 
            position="static" 
            className="top-nav"
            sx={{ bgcolor: "black" }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
  
    )
}

export default Navbar