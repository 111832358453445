import {
    RouterProvider,
} from "react-router-dom";

import {MapRouter} from "../features/map"
import * as sharedComponents from "../shared/components"

const MainNavigation = () => {
    return (
      <>
        <sharedComponents.Navbar />
        <RouterProvider router={MapRouter} />
        <sharedComponents.Footer />
      </>
    )
}

export default MainNavigation