import "./MapFilters.scss"
import { IMapFilter } from "../../../../shared/models/map-models"
import { Button } from "@mui/material"


interface IFilterIconProps {
    filter: IMapFilter
    handleFilterClick: (filter: IMapFilter) => void
}


const FilterIcon = (props: IFilterIconProps) => {

    const svgName = props.filter.name.toLowerCase().replace(" ", "_")

    return (
        <div className="filter-icon">
            <Button
                className={props.filter.selected ? "selected-filter" : ""} 
                variant="outlined" 
                startIcon={<img className="process-icon" src={require(`../../../../../public/assets/process_icons/${svgName}.svg`)}/>}
                onClick={() => props.handleFilterClick(props.filter)}
                disabled={props.filter.disabled}
            >
                {props.filter.name}
            </Button>
        </div>
    )
}

interface IMapFilterProps {
    filters: Array<IMapFilter>
    setSelectedFilter: (filter: IMapFilter) => void
}

const MapFilters = (props: IMapFilterProps) => {

    return (
        <div className="filters-container">
            {props.filters.map(filter => {
                return (
                    <div className="filter-icon-wrapper">
                        <FilterIcon 
                            filter={filter}
                            handleFilterClick={(filter) => props.setSelectedFilter(filter)}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default MapFilters