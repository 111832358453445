import axios from 'axios';
import { ICompanyAddressModel, ICoordinates } from '../../models/company-address-models';
import { IBaseMapFilter } from '../../models/map-models';

const ISTANBUL_URL = process.env.REACT_APP_ISTANBUL_URL;



export const getCompanyAddressesAPI = async (
    boundingBox: Array<ICoordinates>,  // Bounding box is NW, NE, SE, SW
    processes: Array<string> = []
  ): Promise<ICompanyAddressModel[]> => {
  try {
    const body = {
      "bounding_box": boundingBox,
      "processes": processes,
      "limit": 500,
    }
    const response = await axios.post(`${ISTANBUL_URL}/company_addresses/`, body);
    return response.data;
  } catch (error) {
    console.error('Failed to get company addresses:', error);
    return []
  }
};

export const getMapFiltersAPI = (): Promise<IBaseMapFilter[]> => {
  return new Promise((resolve, reject) => {
    resolve([
      {name: "Machining", disabled: true},
      {name: "Sheet Metal", disabled: true},
      {name: "Additive", disabled: true},
      {name: "Molding", disabled: true},
      {name: "Heat Treatment", disabled: true},
      {name: "Casting", disabled: false},
      {name: "Forging", disabled: false},
    ])
  })
}