import React from 'react';
import "./MapboxMap.scss"
import { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { ICompanyAddressModel, ICoordinates } from '../../../../shared/models/company-address-models';
import { debounce } from '@mui/material';
import { convertAddressToMapPoint, convertBoundingBox } from '../../map-utils';
import RedMapMarker from "../../map-assets/red_map_marker.svg"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || ""

interface IMapBoxMapProps {
    companyAddresses: Array<ICompanyAddressModel>
    selectCompanyAddress: (companyAddress: ICompanyAddressModel) => void
    fetchCompanyAddresses: (boundingBox: Array<ICoordinates>) => void
}

const MapBoxMapComponent = (props: IMapBoxMapProps) => {
    const mapContainer = useRef(null);
    const map = React.useRef<mapboxgl.Map | null>(null);

    const [lng, setLng] = useState(-74.000);
    const [lat, setLat] = useState(40.730);
    const [zoom, setZoom] = useState(9);

    const [mapLoaded, setMapLoaded] = useState(false)

    // useEffect on component load
    useEffect(() => {
        if(map.current) return
        
        map.current = new mapboxgl.Map({
            container: mapContainer.current || "",
            style: 'mapbox://styles/spacecowllc-mapbox/cloyr7q7l00ru01qn6hi93i1a',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('load', () => {
            setMapLoaded(true)
            fetch(`${process.env.PUBLIC_URL}/assets/red_map_marker.svg`)
            .then(async res => {
                const svgText = await res.text();
                return'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgText);
            }).then((imageUri) => {
                const image = new Image(40,40)
                image.src = imageUri
                image.onload = () => map.current?.addImage('point-marker', image)

                const bounds = map.current?.getBounds()
                if(bounds){
                    const boundingBox = convertBoundingBox(bounds)
                    props.fetchCompanyAddresses(boundingBox)
                }
            })

            map.current?.on('click', "companyAddressLayer",(e) => {
                const features = map.current?.queryRenderedFeatures(e.point, {
                    layers: ['companyAddressLayer'],
                });
                if (features && features.length > 0) {
                    const clickedFeature = features[0];
                    const clickedAddress = clickedFeature.properties?.address
                    props.selectCompanyAddress(JSON.parse(clickedAddress))
                }
            })

            map.current?.on('move', () => {
                handleMapMove()
            })
            map.current?.on('mouseenter', 'companyAddressLayer', () => {
                if(map.current)
                    map.current.getCanvas().style.cursor = 'pointer'
            });
            map.current?.on('mouseleave', 'companyAddressLayer', () => {
                if(map.current)
                    map.current.getCanvas().style.cursor = ''
            });
        })


      }, []);

      useEffect(() => {
        if(mapLoaded && props.companyAddresses){
            const addressMapPoints = convertAddressToMapPoint(props.companyAddresses)

            // Data source has to be cast to proper type (get source can return multiple different types)
            const currentDataSource = map.current?.getSource("companyAddresses") as GeoJSONSource
            if(currentDataSource){  // If the data source is already on the map, update the data 
                currentDataSource.setData(addressMapPoints) 
            }else{  // If the data source doesn't exist, create it
                map.current?.addSource('companyAddresses', {
                    type: 'geojson',
                    data: addressMapPoints,
                })    

                map.current?.addLayer({
                    id: 'companyAddressLayer',
                    type: 'symbol',
                    source: 'companyAddresses',
                    layout: {
                    'icon-image': 'point-marker',
                    'icon-size': 1,
                    'text-field': '{name}',
                    'text-font': ['Roboto Black', 'Arial Unicode MS Bold'],
                    'text-offset': [1, 0],
                    'text-anchor': 'top-left',
                    'text-justify': 'left',
                    'text-max-width': 8,
                    },
                    paint: {
                        'text-color': 'white'
                    }
                });
            }
        }
      }, [props.companyAddresses])

      const handleMapMove = debounce(() => {
        const bounds = map.current?.getBounds()
        if (bounds){
            const boundingBox = convertBoundingBox(bounds)
            props.fetchCompanyAddresses(boundingBox)
        }
      }, 500)

      
    return (
        <div className='map-wrapper'>
            <div ref={mapContainer} className="map" />
        </div>
    )
}

export default MapBoxMapComponent