import './App.css';
import * as navigation from "./navigation"

function App() {
  return (
    <navigation.MainNavigation />
  );
}

export default App;
